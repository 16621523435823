<template>
  <div class="">
    <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      @click-close-icon="close"
      :style="{ height: '580px', width: setwidth }"
    >
    <div class="share_wrap">
      <p class="title">
        分享好友
      </p>
      <div class="content_wrap">
        <div class="content_head">
          <p>邀请用户成功注册1位，即可获得10个AI小风车</p>
          <p>被邀请用户的首次付费金额，会以当前佣金比例分佣给邀请者</p>
        </div>
        <div class="content_body">
          <div class="content_body_link">
            <!-- link -->
            <div class="kuang partb">
              <!-- <p class="partblink">{{ link }}</p> -->
              <div>
                <p class="share_tip">链接分享</p>
                <img class="share_link" src="@/assets/imgs/share_link.png" alt="">
              </div>
              <div class="isPh">
                <div
                  class="btn phbtn"
                  v-clipboard:copy="link"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <img src="@/assets/imgs/copy.png" />
                  复制链接
                </div>
              </div>
            </div>
            <!-- btn -->
            <div class="parta">
              <div class="ispc">
                <div
                  class="btn"
                  v-clipboard:copy="link"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  <!-- <img src="@/assets/imgs/copy.png" /> -->
                  复制链接
                </div>
              </div>
            </div>
          </div>
          <!-- 海报 -->
          <div class="content_body_report">
            <!-- 海报 -->
            <div class="kuang partb ihpartb">
              <p class="share_tip">邀请海报</p>
              <div class="report_wrap" ref="haibao" id="zheng">
                <img :src="require('@/assets/imgs/report.png')" class="z3" />
                <div class="z2 qrcode" id="qrcode" ref="qrcode"></div>
                <img :src="picUrl" id="downimg" class="z1" v-if="picUrl" />
              </div>
            </div>
             <!-- btn -->
             <div class="parta">
              <div class="ispc">
                <div class="btn" @click="downLoadImg">
                  <!-- <img src="@/assets/imgs/save.png" /> -->
                  下载图片
                </div>
              </div>
            </div>
            <div class="isPh haibtn">
              <div class="btn phbtn" @click="downLoadImg">
                <img src="@/assets/imgs/save.png" />
                下载图片
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <foot></foot> -->
    </div>
    </van-popup>
</div>
</template>

<script>
import QRCode from 'qrcodejs2'
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
import config from '@/config'

export default {
  components: {},
  props: {
    value: {
      default: false,
    },
  },
  data() {
    return {
      link: '',
      picUrl: '',
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
     // 不支持响应式  得刷新页面生效
     setwidth() {
      if (document.body.offsetWidth >= 992) {
        return '900px'
      } else {
        return '330px'
      }
    },
  },
  mounted() {
    this.geturl()
    // this.setQrcode()
    // this.setQrcode()
    // setTimeout(() => {
    //   this.createPic()
    // }, 1200)
  },
  methods: {
    geturl() {
      console.log(config.webHost)
      this.$request('share/get-share-url', {
        url: `${config.webHost}#/home`,
        // url: 'https://ai.fly-ad.cn/#/home',
        member_token: this.useInfo && this.useInfo.member_token,
      }).then((rs) => {
        if (rs && rs.code === 200) {
          this.link = rs.data.url
          this.setQrcode()
          setTimeout(() => {
          this.createPic()
          }, 400)
        }
      })
    },
    DPR() {
      // 获取设备dpi
      if (window.devicePixelRatio && window.devicePixelRatio > 1) {
        return window.devicePixelRatio
      }
      return 1
    },
    base64ToBlob(base64Url) {
      const parts = base64Url.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const size = raw.length
      const uInt8Array = new Uint8Array(size)

      for (let i = 0; i < size; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }

      return new Blob([uInt8Array], { type: contentType })
    },
    // 下载图片到本地
    downLoadImg() {
      const blob = this.base64ToBlob(this.picUrl)
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = '海报.png'
      document.body.appendChild(link) // 添加到DOM中
      link.click() // 模拟点击下载
    },
    setQrcode() {
      new QRCode('qrcode', {
        width: 72, // 设置宽度，单位像素
        height:72, // 设置高度，单位像素
        text: this.link, // 设置二维码内容或跳转地址
        correctLevel: QRCode.CorrectLevel.L
      })
    },
    createPic() {
      const _this = this
      var getPixelRatio = function (context) {
        // 获取设备的PixelRatio
        var backingStore =
          context.backingStorePixelRatio ||
          context.webkitBackingStorePixelRatio ||
          context.mozBackingStorePixelRatio ||
          context.msBackingStorePixelRatio ||
          context.oBackingStorePixelRatio ||
          context.backingStorePixelRatio ||
          0.5
        return (window.devicePixelRatio || 0.5) / backingStore
      }
      //生成的图片名称
      var shareContent = document.getElementById('zheng')
      var width = shareContent.offsetWidth
      var height = shareContent.offsetHeight
      var canvas = document.createElement('canvas')
      var context = canvas.getContext('2d')
      var scale = getPixelRatio(context) //将canvas的容器扩大PixelRatio倍，再将画布缩放，将图像放大PixelRatio倍。
      canvas.width = width * scale
      canvas.height = height * scale
      canvas.style.width = width + 'px'
      canvas.style.height = height + 'px'
      context.scale(scale, scale)
      var opts = {
        useCORS: true,
        allowTaint: false,
        logging: true,
        scale: 1,
        canvas: canvas,
        scrollY: 0,
        scrollX: 0,
        width: width,
        height: height,
        dpi: window.devicePixelRatio,
      }
      html2canvas(document.getElementById('zheng'), opts).then(function (
        canvas
      ) {
        context.imageSmoothingEnabled = false
        context.webkitImageSmoothingEnabled = false
        context.msImageSmoothingEnabled = false
        context.imageSmoothingEnabled = false
        var dataUrl = canvas.toDataURL('image/jpeg', 1.0)
        _this.picUrl = dataUrl
      })
    },

    onCopy() {
      this.$message({
        message: '复制成功',
        type: 'success',
      })
    },
    onError() {
      this.$message({
        message: '复制失败',
        type: 'warning',
      })
    },
    close() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="less" scoped>
.title {
   font-size: 24px;
    // width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    // position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
  }
.share_wrap {
  background-color: #fff;
  // min-height: 100vh;
  // padding-top: 100px;
  box-sizing: border-box;
  .content_head {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    color: #999999;
    border-radius: 12px 12px 0px 0px;
    text-align: center;
    p:nth-of-type(1) {
      font-size: 14px;
      margin-bottom: 6px;
    }
    p:nth-of-type(2) {
      font-size: 14px;
    }
  }
  .btn {
    cursor: pointer;
    width: 210px;
    height: 40px;
    padding: 5px 10px;
    border-radius: 36px;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #416fff;

    img {
      margin-right: 5px;
    }
  }
  .kuang {
    border: 1px solid #AAC1F0 ;
    border-radius: 6px;
    // height: 280px!important;
    // width: 280px!important;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    img.share_link{
      width: 200px;
      margin-top: 120px;
    }
    .share_tip{
      font-size: 20px;
      color: #3d3d3d;
      margin-top: 10px;
    }
  }
  .parta {
    .btn {
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
// pc
@media screen and (min-width: 993px) {
  .content_wrap {
    box-sizing: border-box;
    max-width: 1270px;
    // height: 680px;
    background-color: #fff;
    border-radius: 12px;
    margin: 0 auto;
    // margin-top: 50px;
    // transform: scale(0.7);
    .content_body {
      display: flex;
      color: #3d3d3d;
      font-size: 16px;
      box-sizing: border-box;
      padding: 25px;
      height: 40px;
      >div{
      transform: scale(0.7);
        
      }
      .partb {
        height: 450px;
      }
      .content_body_link {
        margin-right: 25px;
        flex: 1;
        .partb {
          text-align: center;
        }
        .partblink {
          margin-top: 180px;
        }
      }
      .content_body_report {
        flex: 1;
        .partb {
          text-align: center;
        }
        .report_wrap {
          width: 202px;
          height: 360px;
          position: relative;
          margin: 0 auto;
          margin-top: 20px;
          .z1 {
            position: absolute;
            width: 202px;
            height: 360px;
            left: 0px;
            top: 0px;
            z-index: 3;
          }

          .z3 {
            width: 202px;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
          }

          .qrcode {
            width: 72px;
            height: 72px;
            // width: 110px;
            // height: 110px;
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            bottom: 65px;
          }
        }
      }
    }
  }
}
// 手机
@media screen and (min-width: 0px) and (max-width: 992px) {
  .content_wrap {
    box-sizing: border-box;
    max-width: 1270px;
    // height: 680px;
    background-color: #3b3c44;
    border-radius: 12px;
    margin: 0 auto;
    .content_body {
      display: flex;
      color: #ffffff;
      flex-direction: column;
      font-size: 16px;
      box-sizing: border-box;
      padding: 25px;
      background-color: #272d44;

      .content_body_link {
        flex: 1;
        .partb {
          text-align: center;
        }
        .phbtn {
          margin: 0 auto;
          margin-bottom: 20px;
        }
        .partblink {
          margin-top: 20px;
          margin-bottom: 15px;
        }
      }
      .content_body_report {
        flex: 1;
        margin-top: 20px;
        .haibtn {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
        }
        .ihpartb {
          height: 460px;
        }

        .phbtn {
          margin: 0 auto;
          margin-bottom: 20px;
        }

        .partb {
          text-align: center;
        }
        .report_wrap {
          width: 202px;
          height: 360px;
          position: relative;
          margin: 0 auto;
          margin-top: 20px;
          .z1 {
            position: absolute;
            width: 202px;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 3;
          }

          .z3 {
            width: 202px;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
          }

          .qrcode {
            // width: 110px;
            // height: 110px;
            width: 72px;
            height: 72px;
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            // bottom: 72px;
            bottom: 65px;
          }
        }
      }
    }
  }
}
</style>

