<template>
  <div class="pup">
    <van-popup
      v-model="diaValue"
      class="pup"
      round
      @click-overlay="close"
      :close-on-click-overlay="true"
      :closeable="true"
      @click-close-icon="close"
      :style="{ height: '540px', width: '880px' }"
    >
    <!-- todo: 替换title -->
     <p class="title">{{argeeType === 1 ?'飞扬AI助手用户协议':'飞扬AI助手隐私政策'}}</p>
     <div class="content">
        <AgreementService v-if="argeeType == 1"></AgreementService>
        <AgreementPrivacy v-if="argeeType == 2"></AgreementPrivacy>
     </div>
    </van-popup>
  </div>
</template>

<script>
import AgreementService from '@/components/AgreementService'
import AgreementPrivacy from '@/components/AgreementPrivacy'
export default {
  components: { AgreementService,AgreementPrivacy },
  props: {
    value: {
      default: false,
    },
    argeeType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {}
  },
  computed: {
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="less" scoped>
.pup {
  text-align: left;
  .title {
   font-size: 24px;
    // width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    // position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
  }
  /deep/ .van-popup {
    background-color: #fff;
    border-radius: 10px;
  }
  /deep/ .van-popup::-webkit-scrollbar {
      width: 0;
  }
  .content{
    margin: 0 50px;
    padding-bottom: 20px;
    margin-top: 20px;
   line-height: 30px;
  }
}
</style>

