<template>
  <div class="dialog-instruction">
     <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '389px', width: '616px' }"
    >
      <p class="title">提示</p>
      <div class="content">
        <div>
          <img src="@/assets/img/charge.png" alt="">
          <p>小风车不够了！点击下方按钮前往充值。</p>
        </div>
        <div>
          <el-button type="primary" @click="goCharge">前往充值</el-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      default: false,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  created () {
  },
  methods: {
    goCharge () {
      this.$router.push({path: '/createnum'})
      this.close()
    },
    getList() {
      this.$request('/brokerage/list', {
        page: this.currentPage,
        page_size: this.pageSize,
        member_token: this.useInfo && this.useInfo.member_token,
      }).then((rs) => {
        if (rs && rs.code === 200) {
          if (rs.data) {
            this.tableData = rs.data.list
            this.totalcount = rs.data.totalcount
            this.brokerage_fee = rs.data.brokerage_fee
            this.brokerage_ratio = rs.data.brokerage_ratio
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    close() {
      this.$emit('input', false)
    },
  }
}
</script>
<style lang="less" scoped>
.dialog-instruction{
  .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
  }
  .content{
    padding: 0 40px;
    margin-top: 60px;
    overflow: auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    img{
      width: 235px;
      margin-top: 20px;
    }
    p{
      font-size: 16px;
      color: #3d3d3d;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .el-button{
      width: 183px;
      height: 48px;
      background: #4886FF;
      border-radius: 40px;
    }
  }
}
</style>