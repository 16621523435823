<template>
  <div class="layout guide-wrap">
    <guideNav></guideNav>
    <!-- <CommonNav></CommonNav> -->
    <div class="main">
        <sidebar></sidebar>
        <div class="main-right">
          <router-view></router-view>
        </div>
    </div>
  </div>
</template>
<script>
import guideNav from '@/views/guidepage/components/guideNav'
import CommonNav from "@/components/CommonNav.vue";
import sidebar from '@/views/guidepage/components/sidebar'
export default {
  components: { guideNav, sidebar, CommonNav },
  data () {
    return {}
  }
}
</script>
<style lang="less" scoped>
.guide-wrap{
  box-sizing: border-box;
  max-width: @cw;
  min-width: @cw;
  width: 100%;
  margin: 0 auto;
  .main{
    margin-top: 65px;
    display: flex;
    flex-direction: row;
    .main-right{
      flex: 1;
    }
  }
}
</style>