<template>
  <div class="service-wrap">
    <div class="service-list" v-show="showList">
      <ul>
        <li class="first">
          <img class="service_logo" src="@/assets/img/service.png" alt="">
        </li>
        <el-popover
        popper-class="service-pop"
        placement="left"
        width="126"
        v-model="visible">
        <div class="imgBox">
         <img  class="customerImage" :src="customerImage" alt="">
         <!-- <img  class="customerImage" src="@/assets/img/customerImage.png" alt=""> -->
         <p>飞扬AI助手客服</p>
        </div>
          <li slot="reference" >客服</li>
        </el-popover>
        <el-popover
        popper-class="service-pop"
        placement="left"
        width="126"
        v-model="visible1">
        <div  class="imgBox">
         <img class="customerImage" :src="qrImage" alt="">
         <!-- <img class="customerImage" src="@/assets/img/qrImage.png" alt=""> -->
        <p>AIGC学习交流社群</p>
        </div>
          <li slot="reference" >社群</li>
        </el-popover>
      </ul>
    </div>
    <img @click="showList = !showList" class="service_logo" src="@/assets/img/service_logo.png" alt="">
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      visible: false,
      visible1: false,
      showList: true,
      qrImage: '',
      customerImage: ''
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
        isRegister: (state) => state.isRegister,
    }),
  },
  created () {
    this.getqr()
  },
  methods: {
    showKefu () {
      this.visible = !this.visible
    },
    getqr () {
      this.$request('index/getqr',
        { member_token: (this.useInfo && this.useInfo.member_token) || '' },
              'get'
        ).then((rs) => {
            if (rs && rs.code === 200){
              console.log(rs, 'p')
              this.qrImage = rs.data && rs.data.qr_image
              this.customerImage = rs.data && rs.data.customer_service_qr_image
            }
          })
    }
  }
}
</script>
<style lang="less" scoped>

.service-wrap{
  position: absolute;
  position: fixed;
  right: 10px;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0;
  width: 51px;

  .service_logo{
    width: 35px;
    height: 35px;
    margin-top: 10px;
  }
  .service-list{
    background: #fff;
    width: 51px;
    // height: 155px;
    border-radius: 7px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #3D3D3D;
    ul{
      .first{
        background: linear-gradient(180deg, #7482FA 0%, #3DE4FD 100%);
        border-radius: 7px 7px 0 0;
        cursor: auto;
        img{
          width: 30px;
          height: auto;
          border-radius: 7px;
          margin-top: 0;
        }
      }
      li{
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .customerImage{
        width: 100px;
      }
    }
  }
}
.service-pop{
  width: 150px;
  padding: 0;
  padding-left: 0;
      padding-right: 0;
  div.imgBox{
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      p{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #3D3D3D;
        margin-top: 6px;
      }
      img{
        width: 125px;
      }
    }
  }
  .top480{
    bottom: 30px;
    right: 10px;
  }
    .top640{
    // top: 639px;
    bottom: 30px;
    right: 10px;
  }
</style>