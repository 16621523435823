<template>
  <div class="ai-public-header">
    <div class="top">
     <div class="back-warp">
       <el-button type="primary" @click="goBack">
        <img src="@/assets/images/AI/back.png" alt="">返回</el-button>
      <p class="title">
        {{title}}
      </p>
     </div>
      <p class="text" v-if="!imageUrl && !isGeting">{{subTitle}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: ''
    },
    subTitle: {
      default: ''
    },
    imageUrl: {
      default: ''
    },
    isGeting: {
      default: false,
    },
    special: {
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    }
  }
}
</script>
<style lang="less" scoped>
.pt60{
  padding-top: 160px;
}
.ai-public-header{
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  .back{

  }
  .top{
    width: 100%;
    .back-warp{
      position: relative;
     /deep/ .el-button{
        height: 32px;
        line-height: 32px;
        width: 56px;
        padding:0px;
        font-size: 12px;
        display: flex;
        align-items: center;
        position: absolute;
        top: -10px;
        >span{
          display: flex;
        align-items: center;
        }
      }

      img{
        width: 22px;
        vertical-align: middle;
      }
    }
    p{
      font-size: 26px;
      font-weight: 500;
      text-align: center;
      width: 100%;
      color: #3D3D3D;
    }
    .text{
      font-size: 20px;
      margin-top: 10px;
      font-weight: 400;
    }
  }
}
</style>