<template>
  <div class="pup">
    <!-- <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      @click-close-icon="close"
      :style="{ height: '340px', width: '280px' }"
    > -->
      <!-- 扫码 -->
      <p class="title" v-if="!dialogType">
        请使用微信扫一扫登录
        <span class="btn" @click="startLogin">刷新
           <img src="@/assets/imgs/refresh-left.png" alt="">
        </span>
      </p>
      <div class="login_wrap">
        <p class="title">请使用微信扫一扫</p>
        <div id="qrcode"></div>
      </div>
    <!-- </van-popup> -->
  </div>
</template>

<script>
import config from '@/config'
export default {
  props: {
    value: {
      default: false,
    },
    openType: {
      type: String,
      default: '',
    },
    dialogType: {
      type: String,
      default: '', // bind 绑定账户 ‘’空则是登录
    },
  },
  data() {
    return {}
  },
  computed: {
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.startLogin()
    })
  },
  methods: {
    // redirect_uri: encodeURIComponent('https://ai.fly-ad.cn/#/login'),
    startLogin() {
      let redirectUri = encodeURIComponent(`${config.webHost}#/login`)
      // 如果是账号绑定
      if (this.dialogType) {
        redirectUri = encodeURIComponent(`${config.webHost}#/home`)
      }
      new WxLogin({
        self_redirect: false,
        id: 'qrcode',
        appid: 'wxa51a778fe3080148',
        scope: 'snsapi_login', // //snsapi_base
        redirect_uri: redirectUri,
        style: 'black', //提供"black"、"white"可选，默认为黑色文字描述。详见文档底部FAQ
        href: 'data:text/css;base64,Lm9sZC10ZW1wbGF0ZSB7CiAgd2lkdGg6IDI0MHB4ICFpbXBvcnRhbnQ7Cn0KCi5pbXBvd2VyQm94IC5xcmNvZGUgewogIHdpZHRoOiAyMzVweCAhaW1wb3J0YW50OwogIG1hcmdpbi10b3A6IC0zMnB4ICFpbXBvcnRhbnQ7Cn0KCmlmcmFtZSB7CiAgd2lkdGg6IDI0MHB4ICFpbXBvcnRhbnQ7CiAgaGVpZ2h0OiAyNDBweCAhaW1wb3J0YW50Owp9', // css=> base64
      })
    },

    close() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="less" scoped>
.pup {
  .title {
    // width: 100%;
    text-align: center;
    color: #3d3d3d;
    font-size: 14px;
    font-weight: 400;
    // position: absolute;
    // z-index: 1;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #606266;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      color: #131414;
      img{
        width: 16px;
        vertical-align: middle;
        margin-left: 6px;
      }
    }
    .btn{
      cursor: pointer;
    }
  }
  /deep/ .van-popup {
    background-color: #353740;
  }
  .login_wrap {
    width: 100%;
    height: 240px;
    box-sizing: border-box;
    // margin-top: 20px;
    // padding: 54px 30px 0px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .title{
      font-size: 16px;
    }
    #qrcode {
      width: 240px;
      height: 240px;
      transform: scale(0.8);
    }
  }

  .forms {
    width: 100%;
    height: 240px;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 54px 15px 0px;
    box-sizing: border-box;
    position: relative;
    .btn {
      width: 100%;
      height: 48px;
      margin-top: 30px;
      text-align: center;
      cursor: pointer;
      color: @f;
      // background-color: #3f6cea;
      background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
      border-radius: 8px;
    }
    > input {
      width: 100%;
      height: 48px;
      margin-top: 30px;
      border-radius: 8px;
      box-sizing: border-box;
      background-color: #f2f2f2;
      border: none;
      padding: 5px 15px;
    }
    .sendCode {
      width: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      margin-top: 15px;
      border-radius: 8px;
      background: #ffffff;

      > input {
        width: 150px;
        height: 48px;
        background-color: #f2f2f2;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
        padding: 5px 15px;
      }
      > div {
        flex: 1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 38px;
        background: linear-gradient(135deg, #416fff 0%, #00ced1 100%);
        color: #ffffff;
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
      .disableTime {
        background: #b6b7ba !important;
      }
    }
  }
}
</style>

