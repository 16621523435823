<template>
  <div class="head_wrap navh">
    <div class="head_inner">
      <div class="head_l">
        <!-- <img class="fengcheimg" :src="require('@/assets/img/1.gif')" @click="backhome"/> -->
        <img  src="@/assets/imgs/font_logo_new.png" class="logo ml2" @click="backhome" />
      </div>
      <div class="head_r">
        新手指南
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
      loginType: (state) => state.loginType,
      questionNumber: (state) => state.questionNumber,
    }),
  },
  mounted() {
  },
  methods: {
    backhome() {
      // location.reload()
      this.$router.push({
        path: '/home',
      })
    },
  }
}
</script>

<style lang="less" scoped>
@fsize: 16px;
.head_r_teach {
  position: relative;
  .noticeDian {
    width: 5px;
    height: 5px;
    background-color: #FD3A3A;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 4px;
  }
}
  .navh {
    height: 65px;
  }
  .head_wrap {
    width: 100%;
    position: fixed;
    z-index: 100;
    left: 0px;
    top: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #FFFFFF;

    .head_inner {
      position: relative;
      width: 100%;
      max-width: @cw;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .selecedTab {
        color: #0658F9;
        border-radius: 0px;
        border-bottom: 2px solid #0658F9;;
      }
      .head_l {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .fengcheimg{
          width: 50px;
        }
        .logo {
          width: 160px; // 160
          cursor: pointer;
          margin-right: 3px;
        }
        .homePage {
          // color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          // width: 40px;
          height: 28px;
          margin-right: 6px;
          margin-left: 43px;
        }
        // .fengche{
        //   width:
        // }
        .head_r_teach1 {
          // color: @b;
          cursor: pointer;
          // width: 106px;
          margin-left: 40px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          img {
            width: 18px;
            margin-right: 4px;
          }
        }
        .charge{
          // margin-left: 6px;
        }
        .cGray{
            color: #999;
            span{
              display: inline-block;
              font-size: 16px;
              vertical-align: baseline;
              transform: scale(0.5);
              margin-top: 5px;
              position: relative;
              left: -16px;
            }
          }
        .share_friend{
          font-size: 14px!important;
        }

        .head_r_teach {
          // background-color: #3e404a;
          padding: 5px 10px;
          color: @b;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: @fsize;
          border-radius: 20px;
          position: relative;
          .noticeDian {
            width: 4px;
            height: 4px;
            background-color: red;
            position: absolute;
            z-index: 1;
            right: 0px;
            top: 0px;
          }
          img {
            width: 18px;
            margin-right: 4px;
          }
        }
      }
      .head_r {
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-weight: 500;
        color: #3D3D3D;
        font-size: 18px;
      }
    }
  }
</style>

