<template>
  <div class="pup">
    <!-- <van-popup
      v-model="diaValue"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      @click-close-icon="close"
      :style="{ height: '340px', width: '280px' }"
    > -->
      <!-- 短信 -->
      <!-- <p class="title">短信验证码登录</p> -->
      <div class="forms">
        <input
          v-model="obj.mobile"
          type="text"
          maxlength="11"
          placeholder="请输入手机号"
          @input="checkphone"
        />
        <div class="sendCode">
          <input v-model="obj.code" type="text" placeholder="请输入验证码" />
          <div class="sendcode_btn" @click="sendCode" :class="[codeTime ? 'disableTime' : '']">
            {{ codeTime ? `${codeTime}s` : '获取验证码' }}
          </div>
        </div>
        <div class="footer">
          <div v-if="dialogType" class="btn fcenter login_in" @click="goBindMobile">确定</div>
          <div v-else class="btn fcenter login_in" @click="nowsave">登录</div>
        </div>
        <div class="agreement" v-if="!dialogType">
          <van-checkbox v-model="agreeChecked" shape="square" checked-color="transparent" icon-size="14px">
            <span>我已阅读并同意
              <span class="Cblue mr6 ml6" @click.stop="openArgee(1)">服务协议</span>
              和
              <span class="Cblue ml6"  @click.stop="openArgee(2)">隐私政策</span>
            </span>
            <template #icon="props">
              <van-icon name="success" :class="props.checked ? 'Cblue' : ''" />
            </template>
          </van-checkbox>
        </div>
      </div>
      <DialogAgreement
      v-if="showArgeeDialog"
      v-model="showArgeeDialog"
      :argeeType="argeeType"
      ></DialogAgreement>
    <!-- </van-popup> -->
  </div>
</template>

<script>
import { debounce, regPhone } from '@/util/common'
import { mapState } from 'vuex'
import DialogAgreement from '@/components/DialogAgreement.vue'

export default {
  components: { DialogAgreement },
  props: {
    value: {
      default: false,
    },
    openType: {
      type: String,
      default: '',
    },
    dialogType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      obj: {
        mobile: '',
        code: '',
      },
      codeTime: 0,
      intervalId: null,
      agreeChecked: false,
      showArgeeDialog: false,
      argeeType: 1
    }
  },
  computed: {
    ...mapState({
      user_sign: (state) => state.user_sign,
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  mounted() {
    let getTime = localStorage.getItem('time')
    if (getTime && Number(getTime)) {
      if (Number(getTime) <= 0) {
        localStorage.setItem('time', 0)
        this.codeTime = 0
      } else {
        this.codeTime = Number(getTime)
        this.timeHandler(this.codeTime)
      }
    }
  },

  methods: {
    openArgee (val) {
      this.argeeType = val
      this.showArgeeDialog = true
    },
    goBindMobile: debounce(function () {
      // 手机号
      let bol = this.ValidityPhone(1)
      if (!bol) {
        return false
      }

      // 有user_sign 登录带上
      if (this.user_sign) {
        Object.assign(this.obj, { user_sign: this.user_sign })
      }
      this.obj.member_token = this.useInfo && this.useInfo.member_token
      this.$request('login/bindmobile', this.obj).then((rs) => {
        if (rs && rs.code === 200) {
          this.$bus.$emit('close:showBindAccount')
          this.$bus.$emit('update:questionNumber')
          this.$message({
            message: '绑定成功',
            type: 'success',
            showClose: true,
            duration: 2000,
          })
          clearInterval(this.intervalId)
          localStorage.setItem('time', 0)
          this.$store.commit('setUseInfoIsBindStatus', '0')
          this.close()
          this.$router.replace({ path: '/home' })
        }
      })
    }, 500),
    //保存登录
    nowsave: debounce(function () {
      // 手机号
      let bol = this.ValidityPhone(1)
      if (!bol) {
        return false
      }

      // 有user_sign 登录带上
      if (this.user_sign) {
        Object.assign(this.obj, { user_sign: this.user_sign })
      }

      this.$request('login/dologin', this.obj).then((rs) => {
        if (rs && rs.code === 200) {
          this.$message({
            message: '登录成功',
            type: 'success',
            showClose: true,
            duration: 2000,
          })
          clearInterval(this.intervalId)
          localStorage.setItem('time', 0)
          this.$store.commit('setUseInfo', rs.data)
          this.$store.commit('setRegister', rs.data.is_register)
          this.$store.commit('setUserSign', '')
          this.close()
          this.$router.replace({ path: '/home' })
        }
      })
    }, 500),
    // 倒计时
    timeHandler(time) {
      if (time && time > 0) {
        localStorage.setItem('time', time)
        this.codeTime = Number(time)
        this.intervalId = setInterval(() => {
          this.codeTime--
          localStorage.setItem('time', this.codeTime)
          if (this.codeTime === 0) {
            clearInterval(this.intervalId)
          }
        }, 1000)
      }
    },
    // 手机号登录验证
    ValidityPhone(type = 1) {
      if (!this.obj.mobile) {
        this.$message({
          message: '电话号码不能为空',
          type: 'warning',
        })
        return false
      }
      // 正确
      if (!regPhone(this.obj.mobile)) {
        this.$message({
          message: '请输入正确的电话号码',
          type: 'warning',
        })
        return false
      }
      if (type === 1) {
        // code 非空
        if (!this.obj.code) {
          this.$message({
            message: '验证码不能为空',
            type: 'warning',
          })
          return false
        }
        if (!this.agreeChecked && !this.dialogType) {
          this.$message({
            message: '请先阅读服务协议和隐私政策',
            type: 'warning',
          })
          return false
        }
      }
      return true
    },
    // 发送验证码
    sendCode: debounce(function () {
      if (this.codeTime) {
        return
      }
      let bol = this.ValidityPhone(2)
      if (!bol) {
        return false
      }
      this.$request('login/sendsms', {
        mobile: this.obj.mobile,
        captcha: 'cuge',
      }).then((rs) => {
        if (rs && rs.code === 200) {
          this.$message({
            type: 'success',
            message: '发送成功',
          })
          this.timeHandler(60)
        }
      })
    }, 600),
    // 限制只能输入数字
    checkphone() {
      this.obj.mobile = this.obj.mobile
        .replace(/[^\d-]/g, '')
        .replace(/^-+/g, '')
        .replace(/-+$/g, '')
    },

    close() {
      this.$emit('input', false)
    },
    beforeDestroy() {
      if (this.intervalId) clearInterval(this.intervalId)
    },
  },
}
</script>

<style lang="less" scoped>
.pup {
  .agreement{
    width: 100%;
    font-size: 12px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .Cblue{
      color: #1989fa;
    }
    .mr6{
      margin-right: 6px;
    }
    .ml6{
      margin-left: 6px;
    }
    .van-checkbox{
      color: #999;
     /deep/ .van-checkbox__label{
        color: #999;
      }
    /deep/ .van-checkbox__icon--checked {
      .van-icon{
        color: #1989fa;
        background-color: transparent;
        border-color: #1989fa;
      }
    }
    }
  }
  .title {
    width: 100%;
    text-align: center;
    color: #3d3d3d;
    font-size: 21px;
    font-weight: bold;
    // position: absolute;
    // z-index: 1;
    // top: 32px;
  }
  /deep/ .van-popup {
    background-color: #fff;
  }
  .login_wrap {
    width: 100%;
    height: 240px;
    box-sizing: border-box;
    margin-top: 50px;
    padding: 54px 30px 0px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    #qrcode {
      width: 240px;
      height: 240px;
    }
  }

  .forms {
    position: relative;
    margin: 0 40px;
    box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    color: #333;
    input::placeholder{
      font-size: 14px;
      color: #999999;
    }
    .footer{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      font-size: 16px;
    }
    .btn {
      width: 180px;
      height: 40px;
      margin-top: 30px;
      text-align: center;
      cursor: pointer;
      color: @f;
      background: #416fff;
      border-radius: 16px;
    }
    > input {
      width: 100%;
      height: 40px;
      margin-top: 60px;
      border-radius: 8px;
      box-sizing: border-box;
      background-color: #f2f2f2;
      border: none;
      padding: 5px 10px;
    }
    .sendCode {
      width: 100%;
      display: flex;
      align-items: center;
      height: 48px;
      margin-top: 35px;
      border-radius: 8px;
      background: #ffffff;
      position: relative;

      > input {
        width: 100%;
        height: 40px;
        background-color: #f2f2f2;
        border-radius: 8px;
        border: none;
        box-sizing: border-box;
        padding: 5px 15px;
      }
      .sendcode_btn {
        flex: 1;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 94px;
        height: 30px;
        color: #ffffff;
        background: linear-gradient(133deg, #3AEAFD 0%, #797BFA 100%);
        font-size: 12px;
        // margin-left: 5px;
        // margin-right: 5px;
        cursor: pointer;
        position: absolute;
        right: 10px;
      }
      .disableTime {
        background: #b6b7ba !important;
      }
    }
  }
}
</style>

