import { render, staticRenderFns } from "./ai-public-header.vue?vue&type=template&id=9b2b86d2&scoped=true&"
import script from "./ai-public-header.vue?vue&type=script&lang=js&"
export * from "./ai-public-header.vue?vue&type=script&lang=js&"
import style0 from "./ai-public-header.vue?vue&type=style&index=0&id=9b2b86d2&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b2b86d2",
  null
  
)

export default component.exports