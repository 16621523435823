<template>
    <div class="markdown-body" v-html="result"></div>
</template>

<script>
import { marked } from 'marked'
import hljs from 'highlight.js'
import 'highlight.js/styles/monokai-sublime.css'
import 'github-markdown-css'

export default {
  props: {
    plainText: {
      default: '',
    },
  },
  data() {
    return {
      result: '',
    }
  },
  created() {
    const renderer = new marked.Renderer()

    marked.setOptions({
      renderer: renderer,
      highlight: (code, lang) => {
        return lang && ['javascript', 'css', 'html', 'php'].includes(lang)
          ? hljs.highlight(code, { language: lang }).value
          : hljs.highlightAuto(code).value
      },
      langPrefix: 'hljs language-',
      //基本设置
      // gfm: true, //允许 Git Hub标准的markdown.
      // tables: true, //允许支持表格语法（该选项要求 gfm 为true）
      // breaks: true, //允许回车换行（该选项要求 gfm 为true）
      // pedantic: false, //不纠正原始模型任何的不良行为和错误（默认为false）
      // sanitize: false, //对

      gfm: true,
      tables: true,
      smartLists: true,
      smartypants: true,
      xhtml: true,

      breaks: true,
      pedantic: false,
      sanitize: false,
    })
  },

  watch: {
    plainText: {
      immediate: true,
      handler(val) {
        let pp = val && val.replace(/\\n/g, '\n').replace(/\u2003/g, ' ')
        marked.parse(pp, (err, html) => {
          this.result = html
        })
      },
    },
  },
}
</script>

<style lang="less">
ol {
  list-style-type: upper-romancircle;
}

ul {
  list-style-type: upper-romancircle;
}
.markdown-body {
  width: auto;
  // width: 100%;
  background-color: transparent;
  color: #3d3d3d;
  overflow-x: scroll;
  font-size: 14px;
}
.markdown-body ul {
  // list-style: initial;
  // list-style-type: decimal;
  margin: initial;
  padding: 0 0 0 0px;
}
.markdown-body ul li,
.markdown-body ol li {
  display: list-item;
  margin-left: 20px;
  // list-style-type: decimal;
  // list-style: initial;
}

.markdown-body ol {
  // list-style-type: decimal;
  margin: initial;
  padding: 0 0 0 0px;
}
.bread-div {
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
  background-color: #e1f0ff;
}
.detailed-title {
  font-size: 1.8rem;
  text-align: center;
  padding: 1rem;
}
.center {
  text-align: center;
}
.detailed-content {
  padding: 1.3rem;
  font-size: 1rem;
}
pre {
  display: block;
  background-color: #2e2e35;
  padding: 0.5rem !important;
  overflow-y: auto;
  font-weight: 300;
  font-family: Menlo, monospace;
  border-radius: 0.3rem;
}
pre {
  background-color: #000 !important;
}
pre > code {
  border: 0px !important;
  background-color: #000 !important;
  color: #fff;
}

table {
  color: #2e2e35;
}

tbody tr:nth-of-type(odd) {
  background-color: #ebeef5;
}

tbody tr:nth-of-type(even) {
  background-color: #ffffff !important;
}

p,
a,
span,
div,
i,
pre,
em,
del {
  text-decoration: none !important;
}
</style>

