<template>
  <div class="dialog-rebate">
     <van-popup
      v-model="diaValue"
      class="pup rebatepup"
      round
      :close-on-click-overlay="true"
      :closeable="true"
      @click-overlay="close"
      @click-close-icon="close"
      :style="{ height: '540px', width: '880px' }"
    >
    <p class="title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="邀请返利" name="1"></el-tab-pane>
          <el-tab-pane label="历史提现" name="2"></el-tab-pane>
        </el-tabs>
    </p>
      <div class="content">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="邀请返利" name="1"></el-tab-pane>
          <el-tab-pane label="历史提现" name="2"></el-tab-pane>
        </el-tabs> -->
        <div class="rate">当前佣金比例：{{brokerage_ratio || '--'}}%</div>
        <div class="tip">
          <div class="tip_left">
            <i class="el-icon-warning-outline"></i>
            您邀请用户注册后，被邀请用户的首次付费金额，会以当前佣金比例分佣给您！
           </div>
           <div class="tip_right">
            <span class="money">可提现金额：<span class="Cred">¥{{brokerage_fee || 0}}</span></span>
            <el-button size="mini" type="primary" @click="getCash">提现</el-button>
          </div>
        </div>
        <div class="table_wrap" v-if="activeName === '1'">
          <el-table
            :data="tableData"
            style="width: 100%"
            header-cell-class-name="Bgray">
            <el-table-column
              prop="user_share_create_time"
              label="邀请时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="invitee_member_id"
              label="ID"
              >
            </el-table-column>
            <el-table-column
              prop="nickname"
              label="昵称">
            </el-table-column>
            <el-table-column
              prop="is_pay"
              label="是否充值">
            </el-table-column>
            <el-table-column
              prop="order_pay_fee"
              label="充值金额">
            </el-table-column>
            <el-table-column
              prop="brokerage_ratio"
              label="当时佣金比例">
            </el-table-column>
            <el-table-column
              prop="brokerage_fee"
              label="返现金额">
            </el-table-column>
          </el-table>
          <div class="bottom">
            <p>满10元即可发起提现！<img @click="openRuleDialog" class="chatrecord" src="@/assets/img/rule.png" /></p>
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="20"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(totalcount)">
            </el-pagination>
          </div>
        </div>
        <div class="table_wrap" v-if="activeName === '2'">
          <el-table
            :data="tableData1"
            style="width: 100%"
            header-cell-class-name="Bgray">
            <el-table-column
              prop="withdraw_create_time"
              label="提现时间"
              width="180">
            </el-table-column>
            <el-table-column
              prop="withdraw_update_time"
              label="到账时间"
              width="180">
            </el-table-column>
            <el-table-column
              prop="withdraw_bank_card_number"
              label="提现账户">
            </el-table-column>
            <el-table-column
              prop="withdraw_fee"
              label="提现金额">
            </el-table-column>
          </el-table>
          <div class="bottom">
            <p>满10元即可发起提现！ <img @click="openRuleDialog" class="chatrecord" src="@/assets/img/rule.png" /></p>
              <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="currentPage1"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="20"
              layout="total, sizes, prev, pager, next, jumper"
              :total="Number(totalcount1)">
            </el-pagination>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showRule"
      class="pup rulePop"
      round
      :close-on-click-overlay="false"
      :closeable="true"
      :overlay="false"
      @click-close-icon="handleClose"
      :style="{ height: '540px', width: '880px' }"
    >
    <p class="title">分销返佣详细规则说明</p>
    <div class="content">
      <p>一、返佣对象</p>
      <p>所有注册用户都可以通过邀请其他用户获得返佣资格。</p>
      <p>二、返佣规则</p>
      <p> 1.凡是通过您的邀请链接或邀请二维码注册的新用户，都将成为您的邀请对象，他们的首次付费金额，都将按照当前的佣金比例给您返佣。</p>
      <p>2.佣金比例：佣金比例根据公司政策定期调整，具体比例请关注网站公告或咨询客服。</p>
      <p>3.首次付费：只有被邀请用户首次付费金额才能享受返佣，续费或二次消费等不在返佣范围内。</p>
      <p>4.返佣金额：返佣金额=被邀请用户首次付费金额*佣金比例。</p>
     <p> 三、返佣时间</p>
      <p>1.返佣将在被邀请用户成功付费后的7个工作日内，体现在您的【邀请返利】界面。</p>
      <p> 2.满10元（人民币）可提现。从发起提现开始，预计2个工作日可提现到您提供的账户中，具体以银行处理时间为准。</p>
      <p>四、其他规定</p>
      <p>1.若被邀请用户在付费后申请退款，已经发放的返佣将从您的账户中扣除。</p>
      <p>2.若您的邀请行为存在违规情况，如刷单、虚假注册等，公司有权取消您的返佣资格。</p>
      <p>3.您需要确保提供的账户信息准确无误，如因信息错误导致的返佣无法到账，责任由您自行承担。</p>
      <p>五、注意</p>
      <p>1.本规则的解释权归公司所有，如有任何疑问，欢迎随时联系我们。</p>
      <p>2.请您在参与分销返佣活动前，充分阅读并理解以上规则，遵守规则以保障您的权益。</p>
    </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    value: {
      default: false,
    },
  },
  data () {
    return {
      activeName: '1',
      tableData: [],
      pageSize: 20,
      currentPage: 1,
      totalcount: 0,
      brokerage_fee: 0,
      brokerage_ratio: '',
      showRule: false,
      pageSize1: 20,
      tableData1: [],
      totalcount1: 0,
      currentPage1: 1
    }
  },
  computed: {
    ...mapState({
      useInfo: (state) => state.useInfo,
    }),
    diaValue: {
      get() {
        return this.value
      },
      set() {},
    },
  },
  created () {
    this.getList()
    this.getHistoryList()
  },
  methods: {
    getCash () {
      this.$emit('update:showCash',true)
      this.close()
    },
    openRuleDialog () {
      this.showRule = true
    },
    handleClose (){
      this.showRule = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },
    getList() {
      this.$request('/brokerage/list', {
        page: this.currentPage,
        page_size: this.pageSize,
        member_token: this.useInfo && this.useInfo.member_token,
      }, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          if (rs.data) {
            this.tableData = rs.data.list
            this.totalcount = rs.data.totalcount
            this.brokerage_fee = rs.data.brokerage_fee
            this.brokerage_ratio = rs.data.brokerage_ratio
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    getHistoryList () {
      this.$request('/withdraw/list', {
        page: this.currentPage1,
        page_size: this.pageSize1,
        member_token: this.useInfo && this.useInfo.member_token,
      }, 'get').then((rs) => {
        if (rs && rs.code === 200) {
          console.log(rs.data)
          if (rs.data) {
            this.tableData1 = rs.data.list
            this.totalcount1 = rs.data.totalcount
          }
        } else {
          // console.log(rs.message)
        }
      })
    },
    handleSizeChange1(val) {
      this.pageSize1 = val
      this.currentPage1 = 1
      this.getHistoryList()
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val
      this.getHistoryList()
    },
    handleClick(tab, event) {
      this.activeName = tab.name
      if ( this.activeName === '1'){
        this.getList()
      } else {
        this.getHistoryList()
      }
    },
    close() {
      this.$emit('input', false)
    },
  }
}
</script>
<style lang="less" scoped>
.dialog-rebate{
  .rebatepup{
    .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    // justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    padding-top: 20px;
  }
  }
  .content{
    margin: 0 40px;
    margin-top: 90px;
    height: 480px;
    overflow: auto;
    .rate{
      border-bottom: 1px solid #D8D8D8;
      display: flex;
      justify-content: flex-end;
      color: #999;
      font-size: 14px;
      padding-bottom: 6px;
      margin-bottom: 10px;
    }
    .table_wrap{
      min-height: 320px;
     /deep/ .el-table{
        min-height: 320px;
      }
    }
    /deep/ .el-table {
      .Bgray{
        background-color: #F3F3F3!important;
      }
    }
    .tip{
      color: #999;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      i{
        font-size: 16px;
      }
      .tip_right{
        .money{
          margin-right: 6px;
        }
        .Cred{
          color: #FD3A3A;
        }
      }
    }
    .bottom{
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p{
        color: #517DFD;
        font-size: 14px;
        display: flex;
      align-items: center;
      }
      img{
        width: 18px;
      }
    }
  }
  .el-tabs {
    font-size: 16px;
   /deep/ .el-tabs__nav-wrap::after{
    background-color: transparent;
    }
    /deep/ .el-tabs__header{
      padding-bottom: 10px;
      margin-bottom: 0px;
    }
    /deep/.el-tabs__item.is-active{
      color: #517DFD;
    }
    /deep/ .el-tabs__active-bar{
      color:#517DFD;
      background-color: #517DFD;
    }
    /deep/ .el-tabs__item{
      font-size: 18px;
    }
  }
}
.rulePop{
  .title {
    font-size: 20px;
    width: 92%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d8d8;
    margin: 0 20px;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
  }
  .content{
    margin-top: 80px;
    height: 380px;
    overflow: auto;
    box-sizing: border-box;
    p{
      margin-bottom: 10px;
      font-size: 16px;
      margin-left: 16px;
      span{
        font-size: 30px;
        margin: 0 4px;
        vertical-align: sub;
      }
    }
    .special{
      margin-left: 0;
    }
  }
}
.cashPop{
  font-size: 18px;
  .content{
    p{
      span{
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      }
      .Cred{
          color: #FD3A3A;
        }
    }
  }
}
</style>