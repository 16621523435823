import { request } from '@/util/req'

// js生成uuid
function S4() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
}

export function GENERATE_UUID() {
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}

export const regPhone = (phone) => {
  // return /^(13[0-9]|14[4579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(phone)
  return /^(1[3-9][0-9])\d{8}$/.test(phone)
}

// 抖动函数
export function debounce(func, delay) {
  let timer
  delay = delay || 300
  return function (...args) {
    return new Promise((resolve, reject) => {
      try {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          resolve(func.apply(this, args))
        }, delay)
      } catch (error) {
        reject(error)
      }
    })
  }
}

export function isWeixin() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') !== -1) {
    return true
  } else {
    return false
  }
}

export function isPC() {
  var userAgentInfo = navigator.userAgent
  var Agents = new Array(
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  )
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
}

// 判断当前设备是pc还是手机
export const isPc = function () {
  let ua = navigator.userAgent
  let isWindowsPhone = /(?:Windows Phone)/.test(ua)
  let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
  let isAndroid = /(?:Android)/.test(ua)
  let isFireFox = /(?:Firefox)/.test(ua)
  let isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua))
  let isPhone = /(?:iPhone)/.test(ua) && !isTablet
  let isPc = !isPhone && !isAndroid && !isSymbian
  return isPc
}


export const ENV = {
  // 环境
  // 小程序
  1: {
    reg: /miniprogram/g,
    plat: 'wxmini'
  },
  // 微信浏览器 (公众号)
  2: {
    reg: /micromessenger/g,
    plat: 'wxweb'
  },
  // 普通浏览器
  3: {
    plat: 'web'
  }
}

export const randomNum = (num) => {
  return Math.floor(Math.random() * num)
}
