var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('common-nav'),_c('div',{staticClass:"home_wrap pdtop"},[_c('div',{staticClass:"content_wrap"},[_c('div',{staticClass:"content_wrap_inner"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeft),expression:"showLeft"}],staticClass:"main_left"},[_c('div',[_c('van-search',{attrs:{"placeholder":"搜索应用","clear-trigger":"always","clearable":false},on:{"input":_vm.searchName},model:{value:(_vm.searchval),callback:function ($$v) {_vm.searchval=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchval"}})],1),_c('div',{class:[
          'mb10',
          'all_main',
          _vm.tabId === '0' ? 'all_main_selected' : '',
        ],on:{"click":function($event){return _vm.choosetab({ category_id: '0' })}}},[(_vm.tabId === '0')?_c('img',{staticClass:"app_icon",attrs:{"src":require('@/assets/imgs/all_checked.png')}}):_c('img',{staticClass:"app_icon",attrs:{"src":require('@/assets/imgs/all_unchecked.png')}}),_c('span',[_vm._v("全部应用")])]),_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tab_warp",class:[
                              _vm.tabId === item.category_id ? 'main_selected' : '',
                              index !== _vm.tabs.length - 1 ? 'mb10' : '',
                              ['10000', '10001'].includes(item.category_id)?'bGray':''
                            ],on:{"click":function($event){return _vm.choosetab(item)}}},[(_vm.tabId === item.category_id)?_c('img',{staticClass:"app_icon",attrs:{"src":item.category_icon_checked}}):_c('img',{staticClass:"app_icon",attrs:{"src":item.category_icon}}),_c('span',[_vm._v(_vm._s(item.category_name))]),(['10000', '10001'].includes(item.category_id))?_c('span',{staticClass:"see_text"},[_vm._v("2.0版见")]):_vm._e()])})],2),_c('div',{staticClass:"main_right"},[_c('router-view'),_c('foot',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFooter),expression:"showFooter"}]}),_c('Service',{directives:[{name:"show",rawName:"v-show",value:(_vm.showService),expression:"showService"}]})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }