import { doctorBase as doctor } from './axios'

const getSaveUrl = (url) => {
  return url.includes('?') ? url : url.endsWith('/') ? url : url
}
const getUrl = (url) => {
  let urlConfig = {}
  if (typeof url === 'string') {
    urlConfig = { url: getSaveUrl(url), isStatic: false }
  } else {
    urlConfig = {
      url: getSaveUrl((url.prefix || '') + url.url),
      isStatic: false,
    }
  }
  return urlConfig
}
// 遇到静态请求则使用原始axios发送get请求，否则使用http.js中的axios发送请求
const getAxiosRes = (url, params, fn) => {
  return fn()
}
const methods = {
  get(url, params = {}, axios = doctor, config = {}) {
    return getAxiosRes(url, { params }, () =>
      axios.get(getUrl(url).url, { params, ...config })
    )
  },
  post(url, data = {}, axios = doctor, config = {}) {
    return getAxiosRes(url, { params: data }, () =>
      axios.post(getUrl(url).url, data, config)
    )
  },
  put(url, data = {}, axios = doctor) {
    return getAxiosRes(url, { params: data }, () =>
      axios.put(getUrl(url).url, data)
    )
  },
  delete(url, data = {}, axios = doctor) {
    return getAxiosRes(url, { params: data }, () =>
      axios.delete(getUrl(url).url, data)
    )
  },
}
export const request = (url, data = {}, method = 'post', config = {}) => {
  return methods[method](url, data, doctor, config)
}
export default {
  request,
  ...methods,
}

