<template>
  <van-popup
      v-model="showTipsDialog"
      class="pup"
      round
      :close-on-click-overlay="false"
      :closeable="false"
      @click-close-icon="handleClose"
      :style="{ height: '440px', width: '300px' }"
    >
    <p class="title">改版迁移通知</p>
    <div class="content">
      <p>
        Hi，尊敬的用户，
      </p>
      <p>飞扬AI助手1.5版本升级来袭！新增「AI设计」场景，清晰的指令逻辑，助你快速完成AI绘画！</p>
      <p>*鉴于相关备案要求，目前升级改版仅限于PC端，请前往电脑端输入网址ai.fly-ad.cn体验，效果更佳！手机端网页版将停用，手机端微信小程序版8月底上线，敬请期待。为此给您带来的不畅体验，特此免费赠送20个小风车略表心意。</p>
      <p>*飞扬AI助手2.0版本将在2023年9月底上线，AI-PPT、AI抠图、AI消除等多项应用将惊喜开放，敬请期待！</p>
      <p>期待您在新版飞扬AI助手加持下，收获更多乐趣</p>
    </div>
    </van-popup>
</template>
<script>
export default {
  data () {
    return {
      showTipsDialog: true
    }
  },
  methods: {
    handleClose () {
      this.showTipsDialog = false
    },
    openTipsDialog () {
      this.showTipsDialog = true
    },
  }
}
</script>
<style lang="less" scoped>
.pup {
  padding: 0 20px;
  .title {
    width: 90%;
    text-align: center;
    color: #3d3d3d;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 20px;
    background-color: #fff;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #d8d8d8;
    font-size: 20px;
  }
  .content{
    margin-top: 80px;
    font-size: 14px;
    position: relative;
    p{
      text-decoration: underline!important;
      text-decoration-color: #0658F9!important;
      line-height: 28px;
    }
    .last{
      position: absolute;
      margin-top: 180px;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>